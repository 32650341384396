import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Loader } from "../../../common/components/loader";
import { Scrollbar } from "../../../common/components/scrollbar";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { ArticleResponse } from "../../../interfaces/article.interface";
import editorSlice from "../editor/editor.slice";
import { ArticleCard } from "./article-card";

type SettingsData = {
  id?: string;
  name: string;
  templateId: string;
};

export const Articles = (props: any) => {
  const editorState = useSelector((state: RootState) => state.editor);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();

  const getArticles = async () => {
    try {
      const response: any = await axios.post(`${apiUrl}/article/list`, {
        projectId: editorState.project?.id,
      });
      const result = response.data.result as Array<ArticleResponse>;
      dispatch(editorSlice.actions.setArticlesList(result));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing articles...");
      }
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  if (listLoading) {
    return <Loader />;
  }

  return (
    <Scrollbar
      sx={{
        height: "100%",
        overflowX: "hidden",
        "& .simplebar-content": {
          height: "100%",
        },
      }}
    >
      {editorState.articlesList?.map((s, k) => {
        return (
          <div key={k}>
            <ArticleCard data={s} />
          </div>
        );
      })}
    </Scrollbar>
  );
};

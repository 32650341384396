import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { integrationsSlice } from "../../integrations";
import editorSlice from "../editor/editor.slice";

type PublishArticleData = {
  integrationId: string;
  headline: string;
  status: string;
};

type Props = {
  open: boolean;
  onClose: any;
};

export const PublishArticleDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const editorState = useSelector((state: RootState) => state.editor);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<PublishArticleData>({
    mode: "onChange",
    defaultValues: {
      status: "publish",
      integrationId: editorState.project?.integrationId ? editorState.project?.integrationId : undefined,
      headline: editorState.article?.keyword.trim(),
    },
  });

  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const integrationState = useSelector((state: RootState) => state.integrations);

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<PublishArticleData> = async (data) => {
    data.headline = data.headline.trim();
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/article/publish`, {
        ...data,
        id: editorState.article?.id,
      });
      const result = response.data.result;
      toast.success("Successfully posted article" + (result.url ? " to: " + result.url : "!"));
      reset();
      props.onClose();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating integration");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (props.open) getIntegrations();
  }, [props.open]);

  const handleCreateIntegration = () => {
    dispatch(editorSlice.actions.setCreateIntegrationDialogOpened(true));
    dispatch(editorSlice.actions.setPublishDialogOpened(false));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => reset(),
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Publish Article</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              select
              label="Integration"
              defaultValue={getValues("integrationId")}
              disabled={loading}
              {...register("integrationId", {
                required: true,
                validate: { notEqual: (v) => v !== "createIntegration" },
              })}
            >
              {integrationState.integrations &&
                integrationState.integrations.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.data.domain}
                  </MenuItem>
                ))}
              <MenuItem value={"createIntegration"} onClick={handleCreateIntegration}>
                Create a New Integration...
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Headline"
              {...register("headline", { required: true, minLength: 1 })}
              disabled={loading}
            />
            {formState.errors.headline && (
              <FormHelperText error={true}>Please enter a headline for your article!</FormHelperText>
            )}
            {formState.errors.integrationId && (
              <FormHelperText error>Please select a WordPress site to publish to!</FormHelperText>
            )}
            <TextField
              fullWidth
              select
              label="Status"
              defaultValue={getValues("status")}
              disabled={loading}
              {...register("status", { required: true })}
            >
              {["publish", "draft", "pending", "private"].map((t, k) => (
                <MenuItem key={k} value={t}>
                  {t[0].toUpperCase() + t.substring(1)}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <FormHelperText error sx={{ mt: 2 }}></FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit" disabled={!formState.isValid || loading}>
            Publish Article
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

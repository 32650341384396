import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { ArticleResponse } from "../../../interfaces/article.interface";

export const ArticleCard = (props: { data: ArticleResponse }) => {
  return (
    <Card sx={{ mx: "10px", mt: "10px" }}>
      <>
        <CardContent>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {"Status: " + props.data.status}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: 18 }}>
            {props.data.keyword}
          </Typography>
          <Typography variant="body2">
            Sources: {props.data.sources?.length}
          </Typography>
        </CardContent>

        <CardActions sx={{ paddingTop: "0px" }}>
          <Button size="small">Open</Button>
          <Button size="small">Remove</Button>
        </CardActions>
      </>
    </Card>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleResponse } from "../../../interfaces/article.interface";
import { ProjectResponse } from "../../../interfaces/projects.interface";

interface EditorState {
  project: ProjectResponse | null;
  createArticleDialogOpened: boolean;
  updateArticleDialogOpened: boolean;
  shareArticleDialogOpened: boolean;
  updateIntegrationDialogOpened: boolean;
  addSourcesDialogOpened: { opened: boolean; update?: boolean };
  createOutlineInfoDialogOpened: boolean;
  createArticleInfoDialogOpened: boolean;
  createArticleInfoDialogShown: boolean;
  publishInfoDialogOpened: boolean;
  publishInfoDialogShown: boolean;
  createIntegrationDialogOpened: boolean;
  publishDialogOpened: boolean;
  loading: boolean;
  transitionLoading: string | null;
  article: ArticleResponse | null;
  localArticle: ArticleResponse | null;
  articlesList: Array<ArticleResponse>;
  editorEditable: boolean;
  editorSaving: boolean;
  mainButtonActions: Array<string>;
  mainButtonDisabled: boolean;
  editorOpened: "outline" | "article";
  editorTabArticleDisabled: boolean;
}

const MAIN_BUTTON_ACTIONS = {
  generateArticle: "Generate Article",
  generateOutline: "Generate Outline",
  // newArticle: "New Article",
  regenerateArticle: "Regenerate Article",
  regenerateOutline: "Regenerate Outline",
  publish: "Publish Now",
  stopGenerating: "Stop Generating",
  copyArticle: "Copy Article",
  shareArticle: "Share Article",
};

const MAIN_BUTTON_ACTIONS_LIST = {
  generating: [MAIN_BUTTON_ACTIONS.stopGenerating],
  generateOutline: [
    MAIN_BUTTON_ACTIONS.generateOutline,
    // MAIN_BUTTON_ACTIONS.newArticle,
  ],
  generateArticle: [
    MAIN_BUTTON_ACTIONS.generateArticle,
    MAIN_BUTTON_ACTIONS.regenerateOutline,
    MAIN_BUTTON_ACTIONS.publish,
  ],
  publishArticle: [
    MAIN_BUTTON_ACTIONS.publish,
    MAIN_BUTTON_ACTIONS.copyArticle,
    MAIN_BUTTON_ACTIONS.regenerateArticle,
    MAIN_BUTTON_ACTIONS.shareArticle,
  ],
  copyArticle: [
    MAIN_BUTTON_ACTIONS.copyArticle,
    MAIN_BUTTON_ACTIONS.regenerateArticle,
    MAIN_BUTTON_ACTIONS.publish,
    MAIN_BUTTON_ACTIONS.shareArticle,
  ],
  newArticle: [
    MAIN_BUTTON_ACTIONS.publish,
    MAIN_BUTTON_ACTIONS.regenerateArticle,
    MAIN_BUTTON_ACTIONS.regenerateOutline,
    MAIN_BUTTON_ACTIONS.shareArticle,
  ],
};

const initialState: EditorState = {
  project: null,
  loading: true,
  createArticleDialogOpened: false,
  updateArticleDialogOpened: false,
  updateIntegrationDialogOpened: false,
  addSourcesDialogOpened: { opened: false },
  createIntegrationDialogOpened: false,
  createOutlineInfoDialogOpened: false,
  createArticleInfoDialogOpened: false,
  createArticleInfoDialogShown: false,
  shareArticleDialogOpened: false,
  publishInfoDialogOpened: false,
  publishDialogOpened: false,
  publishInfoDialogShown: false,
  article: null,
  localArticle: null,
  articlesList: [],
  editorEditable: false,
  editorSaving: false,
  mainButtonActions: MAIN_BUTTON_ACTIONS_LIST.generateOutline,
  transitionLoading: null,
  mainButtonDisabled: false,
  editorOpened: "outline",
  editorTabArticleDisabled: false,
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectResponse>) => {
      if (!action.payload.articleId) {
        state.createArticleDialogOpened = true;
      }
      state.project = action.payload;
    },
    setArticleDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.createArticleDialogOpened = action.payload;
    },
    setUpdateArticleDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.updateArticleDialogOpened = action.payload;
    },
    setUpdateIntegrationDialogOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.updateIntegrationDialogOpened = action.payload;
    },
    setAddSourcesDialogOpened: (
      state,
      action: PayloadAction<{ opened: boolean; update?: boolean }>
    ) => {
      state.addSourcesDialogOpened = action.payload;
    },
    setCreateOutlineInfoDialogOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.createOutlineInfoDialogOpened = action.payload;
    },
    setCreateArticleInfoDialogOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.createArticleInfoDialogOpened = action.payload;
    },
    setCreateIntegrationDialogOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.createIntegrationDialogOpened = action.payload;
    },
    setPublishDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.publishDialogOpened = action.payload;
    },
    setPublishInfoDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.publishInfoDialogOpened = action.payload;
    },
    setShareArticleDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.shareArticleDialogOpened = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransitionLoading: (state, action: PayloadAction<string | null>) => {
      state.transitionLoading = action.payload;
    },
    setEditorSaving: (state, action: PayloadAction<boolean>) => {
      state.editorSaving = action.payload;
    },
    setEditorEditable: (state, action: PayloadAction<boolean>) => {
      state.editorEditable = action.payload;
    },
    setArticle: (state, action: PayloadAction<ArticleResponse>) => {
      switch (action.payload.status) {
        case "created":
          state.addSourcesDialogOpened = { opened: true, update: false };
          state.editorEditable = false;
          state.editorOpened = "outline";
          state.editorTabArticleDisabled = true;
          break;
        case "added_sources":
          state.updateIntegrationDialogOpened = true;
          state.editorEditable = false;
          state.editorOpened = "outline";
          state.editorTabArticleDisabled = true;
          break;
        case "selected_integration":
          state.createOutlineInfoDialogOpened = true;
          state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generateOutline;
          state.editorEditable = false;
          state.editorOpened = "outline";
          state.editorTabArticleDisabled = true;
          break;
        case "creating_outline":
          state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generating;
          state.editorEditable = false;
          state.editorOpened = "outline";
          state.editorTabArticleDisabled = true;
          break;
        case "created_outline":
          if (!state.createArticleInfoDialogShown) {
            state.createArticleInfoDialogShown = true;
            state.createArticleInfoDialogOpened = true;
          }
          state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generateArticle;
          state.editorEditable = true;
          state.editorOpened = "outline";
          state.editorTabArticleDisabled = true;
          break;
        case "creating_article":
          state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generating;
          state.editorEditable = false;
          state.editorOpened = "article";
          state.editorTabArticleDisabled = false;
          break;
        case "created_article":
          if (!state.publishInfoDialogShown) {
            state.publishInfoDialogShown = true;
            state.publishInfoDialogOpened = true;
          }
          if (state.project?.integrationId) {
            state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.publishArticle;
          } else {
            state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.copyArticle;
          }

          state.editorEditable = true;
          state.editorOpened = "article";
          state.editorTabArticleDisabled = false;
          break;
        case "published":
          state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.newArticle;
          state.editorEditable = true;
          state.editorOpened = "article";
          state.editorTabArticleDisabled = false;
          break;
      }
      state.article = action.payload;
      state.localArticle = action.payload;
    },
    updateLocalArticleContent: (state, action: PayloadAction<string>) => {
      let newArticle = Object.assign({}, state.localArticle);
      newArticle.content = action.payload;
      state.localArticle = newArticle;
    },
    updateLocalArticleOutline: (state, action: PayloadAction<string>) => {
      let newArticle = Object.assign({}, state.localArticle);
      newArticle.outline = action.payload;
      state.localArticle = newArticle;
    },
    updateArticleContent: (state, action: PayloadAction<string>) => {
      let newArticle = Object.assign({}, state.article);
      newArticle.content = action.payload;
      state.article = newArticle;
    },
    setGenerating: (state, action: PayloadAction<boolean>) => {
      let newArticle = Object.assign({}, state.article);
      newArticle.generating = action.payload;
      if (action.payload) {
        state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generating;
      }
      state.article = newArticle;
    },
    reset: () => initialState,
    setArticlesList: (state, action: PayloadAction<Array<ArticleResponse>>) => {
      state.articlesList = action.payload;
    },
    setMainButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.mainButtonDisabled = action.payload;
    },
    setEditorOpened: (state, action: PayloadAction<"article" | "outline">) => {
      state.editorOpened = action.payload;
      if (
        action.payload === "outline" &&
        state.article?.status === "created_article"
      ) {
        state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.generateArticle;
      } else if (
        action.payload === "article" &&
        state.article?.status === "created_article"
      ) {
        state.mainButtonActions = MAIN_BUTTON_ACTIONS_LIST.publishArticle;
      }
    },
  },
});

export type { EditorState };
export const { } = editorSlice.actions;
export { MAIN_BUTTON_ACTIONS };
export default editorSlice;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExploreIcon from "@mui/icons-material/Explore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  CircularProgress,
  Container,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import axios from "axios";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../core/store/store";
import { ToolLeadData } from "../../../interfaces/toolData.interface";
import MaterialMarkdown from "../material-markdown";

interface FormInputs {
  name: string;
  email: string;
  industry: string;
  website: string;
}

const KeywordGenerator: React.FC = () => {
  const [searchParams] = useSearchParams();
  const nameDisabled = searchParams.get("name_disabled") === "true";
  const emailDisabled = searchParams.get("email_disabled") === "true";
  const backgroundColor = searchParams.get("bg_color");
  const buttonColor = searchParams.get("button_color");
  const headlineDisabled = searchParams.get("headline_disabled");
  const disclaimerDisabled = searchParams.get("disclaimer_disabled");

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormInputs>();
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string | null>(null);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [formError, setFormError] = useState("");

  const fetchContent = async (key: string): Promise<boolean> => {
    try {
      const response: any = await axios.post(`${apiUrl}/embed/get-lead`, {
        key,
      });
      const dataResp: ToolLeadData = response.data.result.tool;
      setContent(dataResp.data.content);
      return dataResp.data.finished as boolean;
    } catch (e: any) {
      return true;
    }
  };

  const getResponse: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      setLoading(true);
      setContent(null);

      const response: any = await axios.post(`${apiUrl}/embed/keyword-generator`, data, { timeout: 600000 });

      const dataResp: ToolLeadData = response.data.result.tool;

      // Now setting up the interval to call fetchContent every 5 seconds

      let finished = false;

      while (!finished) {
        finished = await fetchContent(dataResp.key as string);
        await new Promise((resolve) => setInterval(resolve, 3000));
      }
      window.top?.postMessage("gotResponse");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      window.top?.postMessage("gotError");
      if (e.response?.data?.error?.message) {
        // Set custom error on a specific field, for example 'username'
        setFormError(e.response.data.error.message);
      } else if (e.request) {
        setFormError("Network error occurred...");
      } else {
        setFormError("Network error occurred...");
      }
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    window.top?.postMessage("onSubmit");
    getResponse(data);
  };

  const handleBack = () => {
    window.top?.postMessage("handleBack");
    setContent(null);
  };

  document.body.style.backgroundColor = backgroundColor ? backgroundColor : "white";

  const CssTextField = withStyles({
    root: buttonColor
      ? {
          "& label.Mui-focused": {
            color: buttonColor,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: buttonColor,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {},
            "&:hover fieldset": {},
            "&.Mui-focused fieldset": {
              borderColor: buttonColor,
            },
          },
        }
      : {},
  })(TextField);

  if (content) {
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={2} onClick={handleBack} sx={{ "&:hover": { cursor: "pointer" } }}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Back to Form</Typography>
        </Box>
        <MaterialMarkdown markdown={content} />
        {loading && (
          <Stack alignItems="center" sx={{ py: "30px" }}>
            <CircularProgress style={{ color: buttonColor ? buttonColor : undefined }} />
          </Stack>
        )}
      </Box>
    );
  }

  if (loading && !content) {
    return (
      <Box
        sx={{
          position: "absolute", // or use 'fixed' if you want it to stay in the same place when scrolling
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ color: buttonColor ? buttonColor : undefined }} />
        <Typography variant="body1" sx={{ mt: "30px", width: "300px", textAlign: "center" }}>
          Extracting optimal keywords for your website. This may take a few seconds...
        </Typography>
      </Box>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: "5px",
      }}
    >
      <Box my={4}>
        {!headlineDisabled && (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              Find Low-Competition Keywords
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter your name, email, and industry to find low-competition keywords to use for your site.
            </Typography>
          </>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {formError && <FormHelperText error>{formError}</FormHelperText>}
          {!nameDisabled && (
            <CssTextField
              {...register("name", { required: true })}
              label="Full Name"
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={loading}
              error={!!errors.name}
              helperText={errors.name ? "Full name is required" : ""}
            />
          )}
          {!emailDisabled && (
            <CssTextField
              {...register("email", { required: true })}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={loading}
              error={!!errors.email}
              helperText={errors.email ? "Email is required" : ""}
            />
          )}

          <CssTextField
            {...register("industry", { required: true })}
            label="Industry"
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={loading}
            error={!!errors.industry}
            helperText="Enter an industry, service, or product type you wish to generate keywords for."
          />
          <CssTextField
            {...register("website", { required: false })}
            label="Business website (Optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={loading}
            helperText="Enter a business website you wish to generate keywords for."
          />
          <Box mt={2} mb={4}>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={!loading && <ExploreIcon />}
              disabled={loading}
              sx={{
                padding: "10px 22px",
                ".MuiLoadingButton-loadingIndicator": {
                  marginRight: "10px", // Add padding to the right of the loader
                },
                backgroundColor: buttonColor ? buttonColor : undefined,
                "&:hover": {
                  backgroundColor: (theme) => (buttonColor ? alpha(buttonColor, 0.8) : undefined),
                },
              }}
            >
              Generate Keywords
            </LoadingButton>
            {!disclaimerDisabled && (
              <Typography variant="body2" sx={{ mt: 2, color: (theme) => theme.palette.grey[700] }}>
                By clicking "Generate Keywords", you agree to our terms and consent to receive marketing communications.
              </Typography>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default KeywordGenerator;
